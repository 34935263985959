import React from "react";
import ReactHlsPlayer from "react-hls-player";
import "./App.css";

export default class App extends React.Component {
  locStorage = window.localStorage;
  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
    this.state = {
      rMap: this.getLocalStorageItem('airadio-channels') ? this.groupBy(this.getLocalStorageItem('airadio-channels'), (list) => list.statename) : new Map(),
      activeState: undefined,
      isPlaying: true,
      activeStation: undefined,
      darkThemeEnabled: this.getLocalStorageItem('airadio-darktheme')
    };
  }

  setLocalStorageItem(property, value) {
    if (this.locStorage) {
      try {
        this.locStorage.setItem(property, value);
        return true;
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }

  getLocalStorageItem(property) {
    if (this.locStorage && this.locStorage.getItem(property) !== null) {
      return JSON.parse(this.locStorage.getItem(property));
    } else {
      return false;
    }
  }

  componentDidMount() {
    // const proto = window.location.protocol === "https:" ? "https" : "http";
    if (this.getLocalStorageItem('airadio-channels')) {
          this.setState({
            rMap: this.groupBy(this.getLocalStorageItem('airadio-channels'), (list) => list.statename),
          });
    } else {
      fetch('https://cors-proxy-share.vercel.app/api',
      {
        method: 'POST',
        headers:{
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            'my-url': 'https://prasarbharati.org/pb/code/index.php/channels/getRadioChannels'
        })
      })
        .then((response) => response.json())
        .then((jsonData) => {
          if (jsonData && jsonData.payload && jsonData.payload.radiolist) {
            this.locStorage.setItem('airadio-channels', JSON.stringify(jsonData.payload.radiolist));
            this.setState({
              rMap: this.groupBy(
                jsonData.payload.radiolist,
                (list) => list.statename
              ),
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  stateChange = (event) => {
    this.setState({ activeState: this.state.rMap.get(event.target.value) });
  };

  setDarkTheme = () => {
    this.setState({ darkThemeEnabled: !this.state.darkThemeEnabled },
      () => {
        this.setLocalStorageItem('airadio-darktheme', this.state.darkThemeEnabled)
        if (this.state.darkThemeEnabled === true) {
          document.getElementsByTagName('HTML')[0].classList.add('dark');
        } else {
          document.getElementsByTagName('HTML')[0].classList.remove('dark');
        }
      });
  }

  channelChange = (channel) => {
    this.setState({ activeStation: channel, isPlaying:true },
      () => {
        setTimeout(() => {
          this.playerRef.current.play();
        });
      });
  };

  togglePlayback() {
    this.setState({ isPlaying: !this.state.isPlaying },
      () => {
        this.playerRef.current.paused ? this.playerRef.current.play() : this.playerRef.current.pause();
      });
  }

  render() {
    // console.log(this.state);
    return (
      <div>
        <div className="invisible h-0 w-0 opacity-0">
          <ReactHlsPlayer
            src={this.state?.activeStation?.link ? (this.state.activeStation.link).replace('http:', 'https:') : ''}
            playerRef={this.playerRef}
            autoPlay={false}
            controls={false}/>
        </div>
        <div className="absolute right-5 top-5">
          <button type="button" onClick={() => { this.setDarkTheme() }}>
            <svg xmlns="http://www.w3.org/2000/svg" className="xs:h-12 xs:w-12 lg:h-14 lg:w-14" fill={this.state.darkThemeEnabled ? 'white' : 'black'} viewBox="0 0 24 24" stroke={this.state.darkThemeEnabled ? 'white' : 'black'}>
              {!this.state.darkThemeEnabled && (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />)}
              {this.state.darkThemeEnabled && (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />)}
            </svg>
          </button>
        </div>

        <div className="mx-auto p-3 text-sm subpixel-antialiased h-screen w-screen dark:bg-gray-800">
          <div className="p-3 max-w-lg mx-auto rounded-lg shadow-md bg-gradient-to-r from-green-400 to-blue-400
            md:flex xs:text-center md:justify-around md:items-center">
            <p className="md:flex-shrink-0">Choose a state:</p>
            <select className="md:flex-shrink-0 bg-transparent m-2 p-2 border-2"
              id="state"
              onChange={this.stateChange}>
              {[...this.state.rMap].map((entry) => {
                const key = entry[0];
                return (
                  <option key={key} value={key}>
                    {key}
                  </option>
                );
              })}
            </select>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
          </div>
          <div className="p-3 grid place-content-evenly gap-4 grid-cols-2 xs:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
            {this.state.activeState &&
              this.state.activeState.map((channel) => {
                return (
                  <button type="button" key={channel.id}
                    className={
                      (this.state?.darkThemeEnabled ? "focus:outline-white "
                        : "focus:outline-black ") +
                      "flex items-center justify-around py-2 px-4 rounded-lg p-4 h-30 text-center hover:from-pink-500 hover:to-yellow-500" +
                      (this.state?.activeStation?.title === channel.title
                        ? " bg-gradient-to-r from-purple-400 via-pink-500 to-red-500"
                        : " bg-gradient-to-l from-lightBlue-500 to-blue-500")
                    }
                    onClick={() => this.channelChange(channel)}>
                    <span className="font-bold text-md lg:text-lg text-white dark:text-opacity-80 capitalize">
                      {channel.title}
                    </span>
                  </button>
                );
              })}
          </div>
        </div>
        {this.state.activeStation && (
          <div className="fixed left-0 bottom-0 bg-gray-50 text-black dark:bg-gray-900 transition-colors duration-300 dark:text-white p-1 max-w-full min-w-full flex items-center justify-around">
            <button type="button" className={ (this.state?.darkThemeEnabled ? "focus:outline-white "
                        : "focus:outline-black ") + "mx-auto"} onClick={() => { this.togglePlayback() }}>
              <svg  xmlns="http://www.w3.org/2000/svg" className="xs:h-12 xs:w-12 lg:h-14 lg:w-14 stroke-1"
              fill="none" viewBox="0 0 24 24" stroke={this.state.darkThemeEnabled ? 'white' : 'black'}>
              {!this.state.isPlaying && (
                <>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </>
              )}
              {this.state.isPlaying && (<path strokeLinecap="round" strokeLinejoin="round" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />)}
            </svg>
            </button>
          </div>)}
      </div>
    );
  }
}
